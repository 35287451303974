@font-face {
    font-family: DaimlerCS;
    font-weight: normal;
    src: url(./DaimlerCS-Regular.ttf) format("truetype");
}

@font-face {
    font-family: DaimlerCS;
    font-weight: bold;
    src: url(./DaimlerCS-Bold.ttf) format("truetype");
}

@font-face {
    font-family: MBCorpoSTextWEB;
    font-weight: normal;
    src: url(./MBCorpoSText-Regular-Web.ttf) format("truetype");
}

@font-face {
    font-family: MBCorpoSTextWEB;
    font-weight: bold;
    src: url(./MBCorpoSText-Bold-Web.ttf) format("truetype");
}