@import url(./Assets/Fonts/font.css);

#root,
.App {
  height: inherit;
}

.App>.ant-layout {
  min-height: 100vh;
}

.ant-layout-header {
  width: 100%;
  height: 100px;
  padding: 30px 0 0;
  padding: 17.5px 0;
  background-color: #000;
  z-index: 10;
}

.ant-layout-content {
  background-color: #fff;
}

.HeaderBar .Logo {  
  height: 65px;
	width: 517px;
  background-image: url("./Assets/Images/MBDEV_Logo.png");
	background-size: contain;
	background-repeat: no-repeat;
}

.top-header {
	height: 100px;
	width: 100%;
	background-color: #000;
}

.sub-header{
	height: 40px;
	
}

.sub-header-container{
  width: 100vw;
  background-color: #262626 !important; 
}

.sub-header-row{
  height: 100%;
}

.sub-header .HeaderLinks{
  height: 100%;
  display: flex;
}

.sub-header .HeaderLink{
  display: flex;
  align-items: center;
  height: 100%;
}

.sub-header,
.HeaderBar{
  width: 86vw;
  max-width: 1680px;
  margin: auto;
}
 
.HeaderLink > a{
  color: #767676;
  font-family: MBCorpoSText-Regular-Web, monospace;
  margin-right: 15px;
}

.HeaderLink > a:hover{
  color: white;
}

.HeaderLinks-hover{
  height: 1px;
  background-color: #008DFC;
  width: 0;
  margin-left: 0;
  position: absolute;
  transition: left 0.5s, width 0.5s;
}

.retailer-dashboard-logo-container{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.retailer-dashboard-logo {
	height: 20px;
	width: 220px;
	background-image: url('./Assets/Images/retailer_dashboard_logo.svg');
	background-repeat: no-repeat;
	background-size: auto;
}


.logo__mb-developers {
	height: 65px;
	width: 517px;
	background-image: url("./Assets/Images/MBDEV_Logo.png") !important;
	background-size: contain;
	background-repeat: no-repeat;
}

.languageIcon-container {
  display: flex;
  color: #767676;
  align-items: center;
  cursor: pointer;
}

.languageIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.disableButtonResetImageIconStyle {
  height: 40px;
  width: 40px;
  background-image: url("./Assets/Images/disable-reset-icon.png");
  background-size: cover;
  align-content: center;
  margin-left: 50px;
  padding: 20px;
}

.HeaderBar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HeaderItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ExpandedHeaderItems {
  display: flex;
  flex-direction: column;
}

.ExpandedHeaderLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-color: black;
  padding-bottom: 12px;
  z-index: 999;
}

.ExpandedHeaderLinks>span {
  margin: 10px 0;
  margin-right: 30px;
}

.HeaderBar .HeaderLinks {
  padding: 30px 30px 30px 0px;
  display: flex;
  flex-direction: row;
}

.HeaderBar .HeaderLink {
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
}

.HeaderBar .HeaderLink>a {
  font-family: MBCorpoSText-Regular-Web, monospace;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: 1.1px;
  color: #fff;
  text-decoration: underline;
}

.LanguageOption {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  font-family: MBCorpoSText-Regular-Web, monospace;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: 1.1px;
  color: #9D9588;
}

.LanguageOption:hover {
  color: #fff;
  cursor: pointer;
}

.MobileMenuIcon {
  color: #fff;
  width: 30px;
  height: 30px;
  margin-top: 28px;
}

.ant-layout-footer {
  width: 100%;
  padding: 0;
  background-color: #000;
}

.FooterBar {
  width: 86vw;
  min-height: 40px;
  margin: auto;
}


.FooterBar .FooterLinks {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 0px;
  gap: 24px;
}

.FooterLinks > wb-link {
  --color : white;
  font-size: 16px;
}

.FooterBar .FooterLink {
  display: flex;
  margin-left: 50px;
}

.FooterBar .FooterLink>a {
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.2px;
  color: #fff;
  text-decoration: underline;
}

.Dashboard {
  width: 86vw;
  height: 100%;
  max-width: 1680px;
  padding-top: 80px;
  background-color: #fff;
  margin: auto;
}

.ProductKey{
  border-top: 1px solid #e8e8e8;
}

.ProductKey-Text{
  width: 86vw;
  margin: auto;
  padding: 20px 0;
}

.Packages .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
  height: 71px;
}

.Packages .ant-tabs.ant-tabs-top.ant-tabs-card>.ant-tabs-nav {
  z-index: 1;
  margin: 0 0 8px 0;
}

.Intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 30px;
}


.Intro-Text {
  margin-top: 24px;
}

.Intro-Example-Text {
  margin-top: 24px;
}

.Intro-Example-Button {
  margin-top: 24px;
}

.Intro-Note {
  font-size: 18px;
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.Intro-Main {
  font-size: 18px;
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 26px;
}

.Intro-Headline {
  font-size: 36px;
  font-family: MBCorpoSTitle-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  height: 40px;
  margin-bottom: 40px;

}

.Intro-Image {
  width: 50vw;
  max-width: 640px;
  height: 300px;
  background-image: url("./Assets/Images/vimdt-logo-neu.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.Intro-Container {
  width: 100%;
}

.Video-Intro {
  width: 100%;
  max-width: 35rem;
  display: flex;
  flex-direction: column;
}

.Video-Text {
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
}


.Packages {
  margin-top: 70px;
}

.Packages p {
  margin: 0;
}

.Packages>.ant-tabs-card .ant-tabs-content {
  min-height: 120px;
  margin-top: -16px;
}

.Packages>.ant-tabs-card>.ant-tabs-nav::before {
  display: none;
}

.Packages>.ant-tabs-card .ant-tabs-tab {
  min-width: 124px;
  height: 64px;
  margin-right: 10px;
  background-color: #000;
  text-align: center;
  transition: none;
}

.Packages>.ant-tabs-card .ant-tabs-tab-active {
  min-width: 124px;
  height: 64px;
  background-color: #0078D6;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
}

.Packages>.ant-tabs-card .ant-tabs-tab-active::after {
  content: " ";
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #0078D6;
}

.ant-tabs-tab-btn,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  color: #fff;
  width: 100%;
  padding: 0 17px;
}

.PackagePanel {
  background-color: #fff;
}

.PackagePanelInfo {
  background-color: #f8f8f8;
  margin-bottom: 80px;
  padding-top: 26px;
  padding-right: 50px;
  padding-left: 15px;
}

.PackagePanelInfo .ant-card-body {
  padding: 24px 0px;
}

.SettingsSection {
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000;
  padding-left: 30px;
  margin-bottom: 60px;
}

.EditPanel {
  margin-bottom: 0;
  font-size: 17px;
  line-height: 1;
}

.PanelComponent{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
}

.videoPanel .CustomSwitchOnOff .OnOffLabel.OnLabel {
  margin-right: 4px;
  width: 100px;
  text-align: end;
}

.videoPanel .CustomSwitchOnOff .OnOffLabel.OffLabel {
  margin-left: 4px;
  width: 40px;
}

.videoPanel .CustomSwitchOnOff {
  width: 120px;
}

.videoPanel .CustomSwitch {
  justify-content: space-between;
  width: 18rem;
  margin: 1rem 1.5rem;
}

.imagePanel .CustomSwitch {
  justify-content: space-between;
  width: 18rem;
  margin: 1rem 1.5rem;
  min-height: 40px;
}

.specificationPanel .InputPackage .CustomSwitch {
  justify-content: space-between;
  width: 18rem;
  margin: 1rem 1.5rem;
  min-height: 40px;
}

.modelsPanel .CustomSwitch {
  justify-content: space-between;
  width: 18rem;
  margin: 1rem 0;
  min-height: 40px;
}

.modelsPanel .ImageSwitchGroup{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.modelsPanel .ImageSwitchGroup .CustomSwitchLabel2 {
  margin-left: 0px;
  justify-content: space-between;
  width: 18.5rem;
}

.InfoSection {
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000;
  padding: 10px 15px 10px 15px;
  width: fit-content;
}

.loadingSpinner {
  display: flex;
  align-items: center;
  color: #999;
  margin-left: 20px;
}

.loadingSpinner .loadingSpinnerLabel {
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding-right: 10px;
  white-space: nowrap;
}

.videoOutputSettings {
  margin-bottom: 80px;
  border-bottom: 1px solid #d9d9d9;
}

.View360Container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 16px;
}

.wb-360-viewer{
  width: 60%;
  height: 100%;
  margin: 0 auto;
}

.wb-sphere-view {
  width: 60%;
  height: 100%;
  margin: 0 auto;
}

.image360ViewCollapseContainer{
  margin-bottom: 30px;
}

.heading360{
  text-align: center;
  margin-bottom: 20px;
  font-size: 17px;
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.33;
}

.editVehicleImagesMain {
  margin-bottom: 80px;
}

.imagePanel .editVehicleImagesMain__CustomSwitch  {
  display: unset;
  margin: unset;
  width: unset;
  min-height: unset;
}

.MarginPxDistanceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.UploadLogoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.MarginPxDistanceBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.FoundImagesInfo {
  display: flex;
  flex-direction: row;
}

.UploadFileElementContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.UploadFileElement {
  display: flex;
  flex-direction: row;
  align-items: center;
}



.UploadFileSelectComposeContainer {
  display: flex;
  justify-content: space-between;
}

.selectVINBox {
  display: flex;
  margin-bottom: 30px;
}

.compositionPreviewPanel {
  border: 1px solid #ccc;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 60%;
}

.compositionPreviewPanel .CustomInput {
  width: 100%;
}

.composePreviewImage {
  width: 100%;
}

.composePreviewSlider {
  padding: 0px 20px;
  width: 100%;
}

.compositionPreviewHolder{
  display: flex;
  justify-content: center;
}

/*********************************/
/* Ant Design Section            */
/*********************************/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.ant-divider-horizontal.ant-divider-with-text::before {
  transform: none;
}

.ant-divider-horizontal.ant-divider-with-text::after {
  transform: none;
}

.ant-select-selection-item {
  font-family: MBCorpoSText-Regular-Web, monospace;
}

.ant-select-item {
  font-family: MBCorpoSText-Regular-Web, monospace;
}

.ant-table {
  font-family: MBCorpoSText-Regular-Web, monospace;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px !important;
  height: 23px;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid;
}

.ant-switch-handle::before {
  border-radius: 50%;
}

.ant-switch {
  min-width: 50px;
  height: 25px;
  background-image: none !important;
}

.ant-select-arrow {
  color: black;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 30px;
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding-top: 25px;
}

.ant-collapse-borderless>.ant-collapse-item {
  border-bottom: unset;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: unset;
}

.ant-select-single .ant-select-selector {
  background-color: rgba(0, 0, 0, 0);
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  align-items: center;
}

.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: #999;
}

/*********************************/
/* Inputs Section                */
/*********************************/

.CustomInput {
  height: 64px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.CustomInput .ant-input {
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
}

.CustomInputLabel {
  height: 24px;
  padding: 0px 20px;
  margin-top: 5px;
  opacity: 0.4;
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: normal;  
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000;
}

.CustomInputLabel-small .CustomInputLabel {
  font-size: 14px;
}

.CustomInputValue {
  padding: 0px 20px !important;
}

.CustomInputNoValue {
  height: 100%;
  font-size: 20px;
  padding: 0px 20px !important;
}

.InputSuffixIcon {
  margin: 5px;
  font-size: 25px;
}

.InputSuffixIconVideo {
  margin: 5px;
  font-size: 25px;
  right:5px;
  position: relative;
}

.InputPopup {
  max-width: 500px;
}

.videoPanelInfoPanel {
  font-family: MBCorpoSText-Regular-Web, monospace;
  max-width: 650px;
}

.EmptySpaceDiv {
  display: none;
}

.InputPackage {
  margin: 38px 30px 38px 30px;
}

.VideoFilter>.InputPackage {
  margin: 0px 20px;
}

.ButtonPackage {
  margin: 20px 30px 0px 30px;
}

.EquipmentInput {
  width: calc(50% - 10px);
  margin-bottom: 32px;
  margin-top: 20px;
}

.CustomSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0.3rem;
  margin-right: 0.7rem;
  background-color: rgba(0, 0, 0, 0);
}

.CustomSwitchOnOff {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.CustomSwitchOnOff .ant-switch,
.CustomSwitchOnOff .ant-switch:focus {
  box-shadow: none;
  border: 2px solid #000;
}

.CustomSwitchOnOff .ant-switch-handle {
  width: 17px;
  height: 17px;

}

.CustomSwitchOnOff .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 19px);
}

.CustomSwitchOnOff .ant-switch-handle::before {
  background-color: #0078D6;
  box-shadow: none;
}

.CustomSwitchOnOff .ant-switch,
.CustomSwitchOnOff .ant-switch.ant-switch-checked {
  background-color: #f8f8f8;
}

.CustomSwitchLabel {
  margin-right: 15px;
  font-family: MBCorpoSTitle-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000;
}

.CustomImageInput .ant-upload.ant-upload-select-picture-card {
  height: 160px;
  width: 160px;
}

.CustomImageInput--big .ant-upload.ant-upload-select-picture-card {
  width: 200px;
  height: 200px;
}


.CustomImageInput--small .ant-upload.ant-upload-select-picture-card {
  height: 64px;
  width: 64px;
}

.CustomSwitchLabel2 {
  margin-right: 15px;
  font-family: MBCorpoSTitle-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000;
  display: flex;
  margin-left: 10px;
  width: 250px;
  align-items: center;
}



.CustomSwitchOnOff .OnOffLabel {

  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #999;
  white-space: nowrap;
}

.CustomSwitchOnOff .OnOffLabel.OnLabel {
  margin-right: 4px;
  text-align: end;
}

.CustomSwitchOnOff .OnOffLabel.OffLabel {
  margin-left: 4px;
}

.CustomButton {
  display: flex;
  justify-content: flex-end;
}

.CustomButton .ant-btn {
  width: 100%;
  max-width: 200px;
  min-width: 160px;
  height: 50px;
  animation: 0s ease 0s 1 normal none running none;
  background-color: #fff;
  border: solid 2px #000;
}

.CustomButton .ant-btn.filledInButton {
  background-color: #000;
}

.ant-modal-confirm-btns>.ant-btn-default {
  animation: 0s ease 0s 1 normal none running none;
  background-color: #000;
  border: solid 2px #000;
  color: #fff;
  cursor: pointer;
}

.ant-modal-confirm-btns>.ant-btn-primary {
  animation: 0s ease 0s 1 normal none running none;
  background-color: #fff;
  border: solid 2px #000;
  color: #000;
  cursor: pointer;
}

.ant-modal-confirm-btns>.ant-btn-default:hover {
  background-color: #fff;
  border: solid 2px #000;
  color: #000;
  cursor: pointer;
}

.ant-modal-confirm-btns>.ant-btn-primary:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.CustomButton:hover .ant-btn-loading {
  background-color: rgb(36, 36, 36);
}

#createSampleVideo {
  margin-right: 0px;
}

#video-container {
  margin: 0 auto;
  width: 85%;
}
.video-mobile {
  width: 300px;
  margin: auto;
}

.video-standard {
  max-width: 36vw;
  margin: auto;
}

.SwitchGroup {
  margin-top: -40px;
  margin-left: 30px;
  margin-right: 30px;
}

.CustomButton .ant-btn[disabled],
.CustomButton .ant-btn[disabled]:hover,
.CustomButton .ant-btn[disabled]:focus,
.CustomButton .ant-btn[disabled]:active,
.CustomButton .ant-btn[disabled].filledInButton,
.CustomButton .ant-btn[disabled].filledInButton:hover,
.CustomButton .ant-btn[disabled].filledInButton:focus,
.CustomButton .ant-btn[disabled].filledInButton:active {
  color: #fff !important;
  background-color: #000 !important;
  opacity: 0.4 !important;
  border: 2px solid #000 !important;
  border-radius: 0px;
  text-shadow: none;
  box-shadow: none;
}

.CustomButton .ant-btn[disabled]>span,
.CustomButton .ant-btn[disabled]:hover>span,
.CustomButton .ant-btn[disabled]:focus>span,
.CustomButton .ant-btn[disabled]:active>span,
.CustomButton .ant-btn[disabled].filledInButton>span,
.CustomButton .ant-btn[disabled].filledInButton:hover>span,
.CustomButton .ant-btn[disabled].filledInButton:focus>span,
.CustomButton .ant-btn[disabled].filledInButton:active>span {
  color: #fff;
}

.CustomButton .ant-btn:hover {
  background-color: #000;
}

.CustomButton .ant-btn.filledInButton:hover {
  background-color: #fff;
}

.CustomButton .ant-btn>span {
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.1px;
  text-align: center;
  animation: 0s ease 0s 1 normal none running none;
  color: #000;
}

.CustomButton .ant-btn.filledInButton>span {
  color: #fff;
}

.CustomButton .ant-btn:hover>span {
  color: #fff;
}

.CustomButton .ant-btn.filledInButton:hover>span {
  color: #000;
}

.ImageList {
  background: #fff;
}

.ImageList .ant-row{
  width: 100%;
}


.ant-card.ant-card.ImageCard {
  min-width: 140px;
  min-height: 96px;
  cursor: pointer;
}

.ant-card.ant-card.ImageCard .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  min-height: 140px;
  padding: 0px;
  background: #fff;
}

.ImageCardUrl {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border: 1px solid #eee;
}

.ImageCardUrl--empty{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #000;
  color: #fff
}

.ImageCardUrl--empty>svg{
  width: 24px;
  height: 24px;
}

.ImageCardUrl--loading> svg{
  -webkit-animation:spin 1s linear infinite;
  -moz-animation:spin 1s linear infinite;
  animation:spin 1s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(-360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(-360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(-360deg); 
        transform:rotate(-360deg); 
    } 
}

.EquipmentCardUrl {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.ImageCardTitle {
  width: 100%;
  height: 22px;
  margin: 10px 152px 50px 0;
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000;
}

.ImageCardTitleModels {
  display: flex;
  justify-content: center;
  text-align: center;
}

.ImagePopover{
  height: calc(15vh + 15vw);
  width: auto;
}

.VideoAdjustCollapse {
  margin: 19px 15px 19px 15px;
}

.videoPanel .InputPackage .ant-collapse-content,
.imagePanel .InputPackage .ant-collapse-content {
  background-color: #f8f8f8;
}

.videoPanel .InputPackage .ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.imagePanel .image360ViewCollapseContainer .ant-collapse-content-box,
.imagePanel .editVehicleImagesMain .ant-collapse-content-box
 {
  margin: 12px 32px;
}

.specificationPanel .InputPackage .ant-collapse-content {
  background-color: #f8f8f8;
}

.modelsPanel .InputPackage .ant-collapse-content {
  background-color: #f8f8f8;
}

.imagePanel .InputPackage .ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.specificationPanel .InputPackage .ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.modelsPanel .InputPackage .ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.VideoAdjustCollapse .ant-collapse>.ant-collapse-item:last-child {
  border-bottom: unset;
}

.VideoAdjustCollapse .ant-collapse-content {
  font-size: 17.5px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 18px;
}

.VideoCustomInput {
  margin: 1rem 1.3rem;
}

/*********************************/
/* Models Section                */
/*********************************/

.Get-to-know-the-world {
  width: 1292px;
  height: 72px;
  margin: 0 0 0px;
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000;
}

.Get-to-know-the-world .text-style-1 {
  color: #008DFC;
}

.ModelsMenu {
  margin: 25px 0px;
  background-color: #fff;
  
}

.ModelsClasses {
  margin-bottom: 25px;
}

.ModelsClasses .ant-collapse-content {
  border: 1px solid #000;
}

.ModelsClasses .ant-collapse-content .ant-collapse-content-box {
  padding: 10px;
}

.ModelsClasses .ant-collapse-header {
  background-color: #f8f8f8;
  color: #000 !important;
}

.ModelsClasses.ant-collapse-item-active>.ant-collapse-header {
  background-color: #000;
  color: #fff !important;
}

.ModelsInnerMenu .ant-collapse-header {
  background-color: #ffffff;
  border: 1px solid rgb(190, 190, 190);
}

.ModelsInnerMenu .ant-collapse-content {
  border: 1px solid rgb(190, 190, 190);
  background-color: transparent;
}

.ModelsInnerMenu {
  margin: 0 10px;
  margin-bottom: 25px;
  background-color: #fff;
}

.ModelsInnerMenu .ImageList {
  background-color: transparent;
}

.ModelsSwitch.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;

}

.ModelSwitch .input {
  padding-bottom: 3px !important;
}

.CustomButton .ant-btn.filledInButton .ant-btn[disabled] {
  background-color: rgb(223, 5, 5) !important;
  border: none;
}

.CustomButton .ant-btn[disabled]:hover,
.CustomButton .ant-btn[disabled]:focus,
.CustomButton .ant-btn[disabled].filledInButton:hover,
.CustomButton .ant-btn[disabled].filledInButton:focus,
.CustomButton .ant-btn[disabled].filledInButton:active {
  background-color: #3f3e3e !important;
}

.spesificationLabel {
  margin-left: 20px;
}

/*********************************/
/* Filter Equipment Section      */
/*********************************/

.FilterEquipmentClass {
  border: 1px solid #000;
}

.FilterEquipmentClass .ant-collapse-content {
  border-bottom: 1px solid #000;
}

.FilterEquipmentClass .ant-collapse-header {
  border-bottom: 1px solid #000;
}

.FilterEquipmentClass.ant-collapse-item-active>.ant-collapse-header {
  border-bottom: none;
}

/*********************************/
/* Override Section              */
/*********************************/

.reset-margin {
  margin: unset !important;
}

.reset-width {
  width: unset !important;
}

.reset-flex {
  display: unset !important;
}

.imagePanel .CustomSwitch .ant-select {
  margin-right: 8px;
}

.imagePanel .ImageSwitchGroup  .CustomSelect .ant-select {
  width: 100px;
}

.imagePanel .anticon-plus,
.videoPanel .anticon-plus {
  display: none;
}

.image360ViewCollapseContainer .ant-collapse-content,
.editVehicleImagesMain .ant-collapse-content {
  background-color: transparent;
}

.collapse .ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.specificationPanel .CustomSwitch .ant-select {
  margin-right: 8px;
}

.equipmentsPanel .EquipmentFilterCollapse .CustomSwitchLabel {
  margin-right: 15px;
}

.modelsPanel .CustomSwitch .ant-select {
  margin-right: 8px;
}

.imagePanel .CustomSwitchOnOff .OnOffLabel.OffLabel {
  margin-left: 4px;
  width: 30px;
}

.specificationPanel .CustomSwitchOnOff .OnOffLabel.OffLabel {
  margin-left: 4px;
  width: 30px;
}

.modelsPanel .CustomSwitchOnOff .OnOffLabel.OffLabel {
  margin-left: 4px;
  width: 30px;
}

.FoundImagesInfo .ImageCounter,
.FoundImagesInfo .ant-col:nth-last-child(1) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.UploadFileElement>.ant-upload-picture-card-wrapper {
  width: auto;
}

.UploadFileElement>.CustomSwitchLabel {
  display: flex;
  white-space: nowrap;
}

.videoPanel .videoAdjust .CustomSwitch {
  margin-left: 10px;
}

.videoPanel .videoAdjust .panelHeader {
  padding: 0 !important;
  margin-top: 10px !important;
}

.videoPanel .CustomSwitchForDegree {
  margin: 1rem 1.5rem;
}

.videoPanel .CustomSwitchLabel2 {
  margin-left: 22px;
  justify-content: space-between;
  width: 18.5rem;
}

.videoPanel .blingsSetting .CustomSwitch {
  width: 100%;
  margin-right: 0;
  width: 22rem;
}

.videoPanel .blingsSetting .ant-select {
  min-width: 200px;
}

.ant-select-disabled .ant-select-arrow {
  transition: color .5s ease;
  color: rgb(183, 183, 183);
}

#Specification .CustomInput {
  max-width: 450px;
}

.ButtonPack {
  margin-top: -72px;
}

.SingleSwitch {
  margin-top: 0 !important;
}

.video-js .vjs-control-bar {
  background-color: rgba(43, 51, 63, 0) !important;
}

.vjs-big-play-centered .vjs-big-play-button {
  margin-top: -0.8em !important;
  margin-left: -0.8em !important;
  height: 3rem !important;
  width: 3rem !important;
  background-color: black;
  border: none;
  border-radius: 100%;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.video-js .vjs-picture-in-picture-control {
  display: none;
}

.__Blings__player__container.__blings__mobile{
  position: relative !important;
}

.ant-upload.ant-upload-select-picture-card {
    border: 3px dashed #d9d9d9;
    height: 170px;
    width: 170px;
}
/*********************************/
/* Media Section                 */
/*********************************/


@media only screen and (min-width: 2050px) {
  .VideoFixedInput {
    width: 20rem;
  }

  .EquipmentPopover{
    height: calc(10vh + 15vw);
    width: auto;
  }
}



@media only screen and (max-width: 1800px) {
  .EmptySpaceDiv {
    min-width: calc(20rem + 4px);
    display: block;
  }
}

@media only screen and (max-width: 1600px) {
  .specificationPanel .CustomSwitch{
    width: 100%;
  }

  .modelsPanel .ImageSwitchGroup{
    grid-template-columns: repeat(4, 1fr);
    gap: 24px !important;
  }

  .modelsPanel .CustomSwitch{
    width: 100%;
  }

  .modelsPanel .ImageSwitchGroup .CustomSwitchLabel2{
    width: 100%;
  }
}

@media only screen and (max-width: 1710px) {

  .CustomSwitch {
    width: 18rem;
  }

  .CustomSwitchOnOff .OnOffLabel.OnLabel {
    margin-right: 4px;
    text-align: end;
  }

  .CustomSwitchOnOff .OnOffLabel.OffLabel {
    margin-left: 4px;
    width: 30px;
    justify-content: space-between;
  }






}

@media only screen and (max-width: 1459px) {

  .CustomSwitch {
    justify-content: space-between;
  }

  .CustomButton .ant-btn>span {
    font-size: 14px;
  }

  .PackagePanelInfo {
    padding-right: 0px;
    padding-left: 0px;
  }

  .video-mobile{
    max-width: 25vw;
  }
  
  .ant-card.ant-card.ImageCard {
    min-width: 120px;
  }

}

@media only screen and (min-width: 1281px) and (max-width: 1360px) {
  .Intro-Headline {
    height: auto;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1360px) {
  .Intro-Headline {
    height: auto;
  }
}

@media only screen and (max-width: 1280px) {

  .Dashboard {
    padding-top: 80px;
    width: 90vw;
  }

  .ProductKey-Text{
    width: 90vw;
  }

  .Intro {
    margin-top: 20px;
}

  .Get-to-know-the-world {
    height: auto;
  }

  .CustomButton .ant-btn {
    width: 100%;
    min-width: 140px;
  }

  #Spesification .CustomButton .ant-btn {
    min-width: 165px;
  }

  #createSampleVideo {
    margin-right: 0px;
  }

  .VideoAdjustCollapse .ant-collapse-content {
    margin-left: 8px;
  }



  .video-mobile {
    max-width: 30vw;
}

}

@media only screen and (max-width: 1024px) {

  .video-mobile {
    max-width: 40vw;
  }
}

@media only screen and (min-width: 992px) {
  .MobileMenuIcon {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .HeaderLinks {
    padding: 0 !important;
    display: none !important;
  }

  .HeaderLink > a{
    padding: 0;
  }

  .ExpandedHeaderLinks{
    position: absolute;
    width: 100vw;
    left: -7vw;
  }

  .compositionPreviewPanel {
    width: 100% !important;
  }

}

@media only screen and (max-width: 980px) {
  .MarginPxDistanceBox .CustomInput {
    margin-bottom: 20px;
    width: 100%;
  }

  .UploadLogoContainer .MarginPxDistanceContainer {
    flex-direction: row;
    width: 100%;
  }

  .UploadLogoContainer .UploadFileElement {
    width: 100%;
  }

  .UploadLogoContainer {
    flex-direction: column;
  }

  .UploadLogoContainer .CustomInput {
    width: 100%;
    min-width: 100%;
  }

  .UploadFileElementContainer {
    flex-direction: column;
  }
  
  .video-standard {
    max-width: 60vw;
  }



}

@media only screen and (max-width: 950px) {
  .Intro-Headline {
    height: auto;
  }

  .imagePanel .CustomSwitch,
  .specificationPanel .InputPackage .CustomSwitch {
    margin: 0.7rem 0;
  }

  
}

@media only screen and (max-width: 840px) {

  .VideoSwitchGroup .ant-col {
    min-width: 100%;
  }

  .ImageSwitchGroup .ant-col {
    min-width: 100%;
  }

  .videoPanel .CustomSwitch {
    width: auto;
    margin-left: 0;
  }

  .videoPanel .CustomSwitchLabel2 {
    width: auto;
    margin-left: 0;
  }


  .imagePanel .ImageSwitchGroup  .CustomSelect .ant-select {
    width: 150px;
  }
  

  .imagePanel .CustomSwitch,
  .specificationPanel .InputPackage .CustomSwitch {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }


  .specificationPanel .ImageSwitchGroup{
    display: flex;
    flex-direction: column;
  }

  .modelsPanel .CustomSwitch {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: auto;
    margin-left: 0;
  }

  .modelsPanel .ImageSwitchGroup{
    display: flex;
    flex-direction: column;
  }

  .VideoPanelSettings .VideoSwitchGroup{
    display: flex;
    flex-direction: column;
  }

  .FoundImagesShowImagesContainer .CustomSwitch {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .imagePanel .CustomSwitchOnOff {
    margin-right: 16px;
  }

  .specificationPanel .CustomSwitchOnOff {
    justify-content: flex-end;
  }

  .modelsPanel .CustomSwitchOnOff {
    justify-content: flex-end;
  }

  .imagePanel .CustomSwitch .ant-select {
    margin-right: 24px;
  }

  .specificationPanel .CustomSwitch .ant-select {
    margin-right: 24px;
  }

  .modelsPanel .CustomSwitch .ant-select {
    margin-right: 24px;
  }

  .VideoCustomInput {
    margin: 1rem 0rem;
  }



}

/*********************************/
/* Main Mobile Section           */
/*********************************/

@media only screen and (max-width: 768px) {

  .Intro {
    flex-direction: column;
  }

  .IntroHeading {
    margin-bottom: 10px;
  }

  .Intro-Image {
    width: 100%;
    height: calc(100vw * 0.38);
  }

  .Intro-Text {
    width: 100%;
    margin-top: 16px;
    margin-right: 0;
  }

  .Intro-Example-Text {
    margin-top: 24px;
  }

  .Video-Intro {
    margin: 48px 0px;
    max-width: none;
  }

  .EquipmentInput {
    width: 100%;
    margin-top: 60px;
  }

  .SwitchGroup {
    margin-top: -50px;
  }

  .CustomButton .ant-btn {
    width: 100%;
    min-width: 165px;
  }


  #createSampleVideo {
    margin-right: 0px;
  }


  .SwitchGroup .ant-col {
    min-width: 100%;
  }

  .CustomSwitch {
    width: auto;
  }


  .videoPanel .ant-select {
    margin-right: -1px !important;
  }

  .specificationPanel .CustomSwitch {
    min-height: 0;
  }

  .ImagePopOver {
    display: none !important;
  }

  .Intro-Headline {
    width: 75vw;
  }

  .video-mobile {
    width: 60vw;
  }

  .compositionPreviewPanel {
    width: 100% ;
  }

  .editVehicleImagesMain .CustomButton .ant-btn {
    max-width: unset;
  }

}

@media only screen and (max-width: 710px) {

  .CustomInput {
    margin-bottom: 20px;
    width: 100%;
  }

  .VideoPanelSettings .CustomInput {
    width: 100%;
    min-width: 0;
    margin-bottom: 0;
  }

  .modelsPanel .CustomInput {
    width: 100%;
  }

  .ModelsSettingsCollapse .CustomInput {
    width: 100%;
    min-width: 0;
    margin-bottom: 0;
  }

  .FooterBar .FooterLink {
    margin-left: 10px;
    width: 9rem;
    margin-bottom: 10px;
  }

  .FooterBar .FooterLinks {
    gap: 10px;
  }

  .FooterBar ant-col {
    width: 50%;
  }

  .TriState {
    margin-right: 7px;
  }

  .VideoFilter .CustomInput {
    width: calc(100vw - 210px) !important;
    min-width: 0 !important;
    margin-bottom: 0 !important;
  }



  .videoPanelInfoPanel {
    max-width: calc(100vw - 65px);
  }

  .ButtonPack {
    margin-top: 40px;
  }

  .FoundImagesInfo {
    flex-direction: column;
    gap: 10px;
  }

  .FoundImagesShowImagesContainer {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .video-mobile {
    max-width: 80vw;
    margin: auto;
    margin-left: auto !important;
    transform: none !important;

  }

  .video-standard {
    max-width: 80vw;
  }
  
  .video-mobile .video-container {
    max-width: 100vw !important;
  }


  .compositionPreviewPanel {
    width: 100%;
  }

  
  
}

@media only screen and (max-width: 670px) {
  .ButtonPackage .ant-row .ant-col {
    margin: 0 !important;
    width: 100%;
    display: block;
  }

  .videoPanel .blingsSetting .CustomSwitch {
    display: flex;
    width: 100%;
  }


  .ButtonPackage .ant-btn,
  .editVehicleImagesMain .ant-btn {
    margin-bottom: 12px;
    max-width: 100%;
  }
  
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }

 
}


@media only screen and (max-width: 610px) {

  .CustomSwitchOnOff .OnOffLabel.OffLabel {
    width: 30px;
  }

  .IntroHeading {
    margin-bottom: 10px;
  }

  .CustomSwitch {
    margin-right: 0.7rem;
  }

  .ant-divider-inner-text {
    max-width: 70vw;
    white-space: normal;
  }

  .ant-divider-horizontal.ant-divider-with-text-left::after {
    min-width: 25%;
    width: initial;
  }

  .imagePanel .editVehicleImagesMain__CustomSelect .ant-select,
  .image360ViewCollapseContainer .selectVINBox  .ant-select,
  .wb-360-viewer,
  .wb-sphere-view
  {
    width: 100%;
  }
}

@media only screen and (max-width: 570px) {
  .UploadFileSelectComposeContainer {
    flex-direction: row;
    align-items: center;
    gap: 25px;
  }

  .ComposeImagesButtonContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .ant-card.ant-card.ImageCard {
    min-width: 120px;
  }

  .retailer-dashboard-logo{
    height: 16px;
    background-size: contain;
  }

  .HeaderBar .Logo {  
    width: 270px;
  }

  
  .imagePanel .ImageSwitchGroup .CustomSelect {
    display: block;
  }

  .imagePanel .ImageSwitchGroup  .CustomSelect .ant-select {
    width: 100%;
  }

}

@media only screen and (max-width: 414px) {



  .ant-layout-header {
    height: 100px;
  }


  .HeaderBar .Logo {
    width: 280px;
    height: 65px;
  }

  .sub-header,
  .HeaderBar {
    width: 90vw;
}

.ExpandedHeaderLinks {
  left: -5vw;
}

  .retailer-dashboard-logo{
    background-size: contain;
    height: 16px;
  }

  .HeaderBar .HeaderLinks {
    padding: 15px 10px;
  }

  .HeaderBar .HeaderLink {
    margin-left: 15px;
  }

  .LanguageOption {
    margin-left: 5px !important;
    font-size: 10px;
  }

  .HeaderBar .HeaderLink>a {
    font-size: 8px;
  }

  .Models .ant-select {
    margin-bottom: 30px;

  }

  .Dashboard {
    padding-top: 20px;
  }

  .CustomInput {
    margin-bottom: 20px;
    width: calc(100vw - 80px);
  }

  .FilterEquipmentClass .CustomInput {
    width: calc(100vw - 70px);
  }

  .spesificationLabel {
    margin-left: 0;
  }


  .VideoFilter .InputPackage {
    margin-left: 0;
    margin-right: 0;
  }

  .VideoFilter .CustomInput {
    width: calc(100vw - 130px) !important;
  }

  .VideoAdjustCollapse {
    margin: 15px 5px;
  }

  .VideoAdjustCollapse .CustomInputLabel {
    font-size: 16px;
  }

  .VideoAdjustCollapse .ant-collapse-content {
    margin-left: 8px;
  }

  .VideoSettingsCollapse {
    padding-left: 0;
  }


  .VideoPanelSettings .CustomInput {
    width: 100%;
    min-width: 0;
  }

  .modelsPanel .CustomInput {
    width: 100%;
  }

  .ModelsSettingsCollapse .CustomInput {
    width: 100%;
    min-width: 0;
  }

  .InputSuffixIconVideo {
    margin-left: 5px;
  }

  .SwitchGroup {
    margin-left: 20px;
    margin-right: 20px;
  }

  .InputPackage {
    margin: 38px 20px;
  }

  .ButtonPackage {
    margin: 0 20px;
  }

  .ant-card.ant-card.ImageCard {
    min-width: 110px;
  }

  .DownloadModels {
    margin-right: 20px;
  }

  .video-mobile {
    width: 80vw !important;
  }
  
  .modelsPanel .ImageSwitchGroup .CustomSwitchLabel2 .ant-select{
    margin-left: 0px !important;
  }

  .CustomSelect {
    display: block;
  }

  .CustomSwitchLabel2 {
    display: block;
  }

  .ant-collapse-content-box .ant-select {
    width: 100% !important;
  }
}


@media only screen and (max-width: 370px) {

  .FooterBar .FooterLink {
    width: 8rem;
  }

  .ModelsInnerMenu {
    margin: 10px 0;
  }

  .VideoAdjustCollapse .CustomInputLabel {
    padding: 0px 5px;
    font-size: 14px;
  }

}

@media only screen and (max-width: 350px) {

  .CustomSwitchOnOff .OnOffLabel {
    font-size: 16px;
  }

  .SwitchGroup {
    margin-left: 15px;
    margin-right: 10px;
  }

}

.DownloadModels {
  font-family: MBCorpoSText-Regular-Web, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000;
  padding-left: 30px;
  padding-bottom: 15px;
  margin-right: 30px;
}

.btn-primary:hover {
  background-color: #0157b3;
}

@font-face {
  font-family: MBCorpoSText-Regular-Web;
  src: local(MBCorpoSText-Regular-Web),
    url("./Assets/Fonts/MBCorpoSText-Regular-Web.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MBCorpoSTitle-Regular-Web;
  src: local(MBCorpoSTitle-Regular-Web),
    url("./Assets/Fonts/MBCorpoSTitle-Regular-Web.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}